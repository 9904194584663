<template>
  <div>
    <b-modal
      id="modal_duplicar_formulario"
      ok-only
      size="md"
      header-bg-variant="info"
      hide-footer
    >
      <template
        #modal-header
      >
        <div class="text-white">
          <strong class="float-left">
            Duplicar formulario
          </strong></div>

      </template>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col
              xl="12"
              lg="12"
              md="12"
              sm="12"
            >
              <validation-provider
                #default="validationContext"
                name="nuevoNombreFormulario"
                rules="required"
              >
                <b-form-group
                  label="Nombre del formulario"
                  label-for="nuevoNombreFormulario"
                >
                  <b-form-textarea
                    id="nuevoNombreFormulario"
                    v-model="jsonFormulario.nombre"
                    rows="3"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback v-if="validationContext.errors[0]">
                    El campo es requerido
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>
          <!-- Form Actions -->
          <div class="d-flex justify-content-between mt-2 border-top pt-1">
            <b-button
              type="button"
              variant="outline-danger"
              size="sm"
              @click="$bvModal.hide('modal_duplicar_formulario')"
            >
              <feather-icon
                icon="XIcon"
                size="14"
              />
              Cancelar
            </b-button>
            <b-button
              :variant="`outline-success`"
              class="mr-2"
              type="submit"
            >
              Generar duplicado
              <feather-icon
                icon="CopyIcon"
                size="14"
              />
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {
  BButton, BModal, BForm, BFormGroup, BFormInvalidFeedback, BCol, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormTextarea,
    BButton,
    BCol,
    BRow,
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      // nuevoNombreFormulario: '',
      jsonFormulario: {},
    }
  },

  created() {
    this.$parent.$on('abrirModalDuplicarFormulario', this.abrirModalDuplicarFormulario)
  },
  methods: {
    async abrirModalDuplicarFormulario(item) {
      // this.nuevoNombreFormulario = `${item.nombre}__COPIA`
      await this.obtenerJsonFormulario(item.id)
      this.$bvModal.show('modal_duplicar_formulario')
    },
    async obtenerJsonFormulario(idFormulario) {
      let formulario = {}
      await this.$http.get(`/recursos/formulario/${idFormulario}`)
        .then(res => {
          formulario = res.data
          delete formulario.id
          delete formulario.created_at
          delete formulario.updated_at
          delete formulario.url_detalles
          delete formulario.version
          formulario.estado = 0

          formulario.secciones.forEach((value, index1) => {
            delete formulario.secciones[index1].id
            delete formulario.secciones[index1].random
            delete formulario.secciones[index1].formulario_id
            delete formulario.secciones[index1].posicion

            formulario.secciones[index1].preguntas.forEach((value2, index2) => {
              delete formulario.secciones[index1].preguntas[index2].id
              delete formulario.secciones[index1].preguntas[index2].random
              delete formulario.secciones[index1].preguntas[index2].seccion_id
              delete formulario.secciones[index1].preguntas[index2].posicion

              formulario.secciones[index1].preguntas[index2].detalles.forEach((value3, index3) => {
                delete formulario.secciones[index1].preguntas[index2].detalles[index3].id
                delete formulario.secciones[index1].preguntas[index2].detalles[index3].random
                delete formulario.secciones[index1].preguntas[index2].detalles[index3].posicion
              })

              formulario.secciones[index1].preguntas[index2].compuesta.forEach((value4, index4) => {
                delete formulario.secciones[index1].preguntas[index2].compuesta[index4].id
                delete formulario.secciones[index1].preguntas[index2].compuesta[index4].random
                delete formulario.secciones[index1].preguntas[index2].compuesta[index4].pregunta_id
                delete formulario.secciones[index1].preguntas[index2].compuesta[index4].posicion

                formulario.secciones[index1].preguntas[index2].compuesta[index4].detalles.forEach((value5, index5) => {
                  delete formulario.secciones[index1].preguntas[index2].compuesta[index4].detalles[index5].id
                  delete formulario.secciones[index1].preguntas[index2].compuesta[index4].detalles[index5].random
                  delete formulario.secciones[index1].preguntas[index2].compuesta[index4].detalles[index5].posicion
                })

                // })
              })
            })
          })
        })
        .catch(() => {
        })
      this.jsonFormulario = JSON.parse(JSON.stringify(formulario))
      this.jsonFormulario.nombre = `${this.jsonFormulario.nombre}__COPIA`
    },
    onSubmit() {
      const JSON_FORMULARIO = [this.jsonFormulario]
      this.$http.post('/recursos/formulario', JSON_FORMULARIO)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Formulario duplicado correctamente!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaSatisfaccion')
          this.$bvModal.hide('modal_duplicar_formulario')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error al duplicar formulario!',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>
