<template>
  <div>
    <div>
      <form-wizard
        ref="refFormWizard"
        next-button-text="Siguiente"
        back-button-text="Anterior"
        color="#06634c"
        :title="null"
        :subtitle="null"
        layout="horizontal"
        :finish-button-text="!estadoFormulario ? 'Enviar respuestas' : null"
        class="wizard-horizontal"
        style="background:#F4F4F4"
        @on-complete="EnviarFormulario"
        @on-change="onchange"
      >
        <tab-content
          v-for="(item, index) in seccionesFormulario"
          :key="index"
          class="px-0 py-0"
          :before-change="beforeOnchange"
        >
          <b-card
            class="bg-light-primary m-0"
          >
            <span style="font-size:16px"><strong>{{ item.titulo }}</strong></span><br>
            <span
              class="ml-2"
              style="font-size:13px"
            >{{ item.subtitulo }}</span>
          </b-card>
          <div v-if="item.condicional === 1">
            <b-card class="py-1 mt-1">
              <b-form-checkbox
                v-model="respuestaCondicional[index].value"
                switch
                :value="false"
                :unchecked-value="true"
                class="custom-control-primary"
                :disabled="estadoFormulario"
              >
                {{ item.condicional_texto }}
              </b-form-checkbox>
            </b-card>
          </div>
          <div v-if="respuestaCondicional[index].value === true">
            <preguntas
              class="mt-1"
              :preguntas="item.preguntas"
            />
          </div>
        </tab-content>
      </form-wizard>
    </div>

  </div>
</template>

<script>
import { BCard, BFormCheckbox } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import store from '@/store'
import { mapState } from 'vuex'
import preguntas from '@/views/administrador/gestion-formulario/formulario/Preguntas.vue'

export default {
  components: {
    BCard,
    // externs
    preguntas,
    FormWizard,
    TabContent,
    BFormCheckbox,
  },
  props: {
    seccionesFormulario: {
      type: Array,
      default: () => [],
    },
    idUsuario: {
      type: Number,
      default: null,
    },
    vistaPrevia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalSecciones: this.seccionesFormulario.length,
      seccionActual: 0,
      formData: new FormData(),
      varPasamos: true, // variable que nos iformará si cambiamos ala siguiente sección
      contador: 0,

      // arrayIndiceAnonimo: [],
      // indexx: 0,
      envioFormularioanonimo: false,
    }
  },
  computed: {
    ...mapState('formularioModule', ['autentificacion', 'estadoFormulario', 'respuestasIdCompuesta', 'respuestaCondicional', 'varRequerido', 'varCompuestaRequerido', 'respuestaCorta', 'parrafo', 'variasOpciones', 'mixtoVariasOpciones',
      'casillas', 'mixtoCasillas', 'desplegable', 'optionsDesplegable', 'mixtoDesplegable',
      'optionsMixtoDesplegable', 'unoMixtoDesplegable', 'archivo', 'fecha', 'hora',
      'compuesta', 'optionsCompuestaDesplegable', 'optionsCompuestaMixtoDesplegable', 'unoEnCompuestaMixtoDesplegable']),
  },

  methods: {
    toast(text, tipo, titulo) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: 'BellIcon',
          text,
          variant: tipo,
        },
      })
    },

    onchange(prevIndex, nextIndex) {
      this.seccionActual = nextIndex
    },

    beforeOnchange() {
      if (this.estadoFormulario) { // Consultamos el estado del formulario para proceder a la validacion
        return true
      }
      // en caso el formulario sea caso vista previa noguardamos data ni guardamos respuestas
      if (this.vistaPrevia) {
        if (this.verificarSeccion()) {
          return true
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Existe campos requeridos sin completar',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
        return false
      }

      if (store.state.formularioModule.autentificacion === 1) {
        this.formData = new FormData()
        if (this.respuestaCondicional[this.seccionActual].value === false) {
          this.limpiarPreguntasSeccion()
          this.formData.append('secciones[0][respuesta]', 1)
        } else {
          this.formData.append('secciones[0][respuesta]', 0)
        }
        this.formData.append('secciones[0][id]', this.respuestaCondicional[this.seccionActual].id)
      } else {
        if (this.respuestaCondicional[this.seccionActual].value === false) {
          this.limpiarPreguntasSeccion()
          this.formData.append(`secciones[${this.seccionActual}][respuesta]`, 1)
        } else {
          this.formData.append(`secciones[${this.seccionActual}][respuesta]`, 0)
        }
        this.formData.append(`secciones[${this.seccionActual}][id]`, this.respuestaCondicional[this.seccionActual].id)
      }

      if (this.idUsuario !== 0 && this.autentificacion === 1) {
        this.formData.append('usuario_id', this.idUsuario)
      }
      // VEMOS SI HAY ALGUNA PREGUNTA REQUERIDA QUE SE ENCUENTRA INCOMPLETA
      if (this.verificarSeccion()) {
        /* ENVIO DE LAS RESPUESTAS */
        let POST_RUTA = 'postRespuestaAnonimo'

        if (store.state.formularioModule.autentificacion === 1) {
          POST_RUTA = 'postRespuestaAuth'
          store.dispatch(`formularioModule/${POST_RUTA}`, this.formData).then(() => {
          }).catch(() => {
            console.log(this.formData)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '¡ALGO SALIO MAL, COMUNICAR AL ADMINISTRADOR!',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
        }

        return true
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Existe campos requeridos sin completar',
          icon: 'XIcon',
          variant: 'warning',
        },
      })

      return false
    },

    swalSuccess(texto) {
      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: texto,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    verificarSeccion() {
      if (store.state.formularioModule.autentificacion === 1) {
        this.contador = 0
      }
      this.varPasamos = true // variable que indica si se pasa o no a una siguiente seccion (si se pasa se guarda los resultados de las preguntas que contiene la seccion validada)
      // varRequerido = variable que pinta de rojo el bCard de la pregunta en caso no ha pasado la validación
      let condicionSeccion = false
      if (this.respuestaCondicional[this.seccionActual].value === false) {
        condicionSeccion = true
      }

      this.seccionesFormulario[this.seccionActual].preguntas.forEach(pregunta => {
        switch (pregunta.tipo) {
          case 'respuesta_corta':
            Object.entries(this.respuestaCorta).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2 === '' || value2 === null) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }

                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2)
                this.contador += 1
              }
            })
            break

          case 'parrafo':
            Object.entries(this.parrafo).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2 === '' || value2 === null) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }

                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2)
                this.contador += 1
              }
            })
            break

          case 'varias_opciones':
            Object.entries(this.variasOpciones).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2 === '' || value2 === null) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }

                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2)
                this.contador += 1
              }
            })
            break

          case 'mixto_varias_opciones':
            Object.entries(this.mixtoVariasOpciones).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2.respuesta.respuesta === undefined) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else if (value2.respuesta.mixto === 1) {
                    if (value2.mixtoValue === '' || value2.mixtoValue === null) {
                      this.varRequerido[key2] = false
                      this.varPasamos = false
                    } else { this.varRequerido[key2] = true }
                  } else { this.varRequerido[key2] = true }
                }
                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2.respuesta.id)
                if (value2.respuesta.mixto === 1) {
                  this.formData.append(`detalles[${this.contador}][mixto]`, value2.mixtoValue)
                } else {
                  this.formData.append(`detalles[${this.contador}][mixto]`, '')
                }

                this.contador += 1
              }
            })
            break

          case 'casillas':
            Object.entries(this.casillas).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2.length === 0) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }
                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)

                value2.forEach(value3 => {
                  this.formData.append(`detalles[${this.contador}][respuesta][]`, value3)
                })

                this.contador += 1
              }
            })
            break

          case 'mixto_casillas':
            Object.entries(this.mixtoCasillas).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  let pasa = false
                  let pasa2 = true

                  Object.entries(value2).forEach(([key3, value3]) => {
                    // eslint-disable-next-line eqeqeq
                    if (value3.checked == 1 && pregunta.detalles.find(val => val.id == key3).mixto == 0) {
                      pasa = true
                      // eslint-disable-next-line eqeqeq
                    } else if (value3.checked == 1 && pregunta.detalles.find(val => val.id == key3).mixto == 1) {
                      if (value3.mixtoValue !== '') {
                        pasa = true
                      } else {
                        pasa = false
                        pasa2 = false
                      }
                    }
                  })

                  if (pasa === false || pasa2 === false) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }
                /* SETEAR RESULTADOS DE LA PREGUNTA PARA SU POSTERIOR GUARDADO */
                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                Object.entries(value2).forEach(([key3, value3]) => {
                  // eslint-disable-next-line eqeqeq
                  if (value3.checked == 1) {
                    this.formData.append(`detalles[${this.contador}][respuesta][]`, key3)
                    this.formData.append(`detalles[${this.contador}][mixto][]`, value3.mixtoValue)
                  }
                })
                this.contador += 1
              }
            })
            break

          case 'desplegable':
            Object.entries(this.desplegable).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2 === '' || value2 === null) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }

                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2)

                this.contador += 1
              }
            })
            break

          case 'mixto_desplegable':
            Object.entries(this.mixtoDesplegable).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2.respuesta.respuesta === undefined) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false

                    // eslint-disable-next-line eqeqeq
                  } else if (value2.respuesta.mixto == 1) {
                    if (value2.mixtoValue === '' || value2.mixtoValue === null) {
                      this.varRequerido[key2] = false
                      this.varPasamos = false
                    } else { this.varRequerido[key2] = true }
                  } else { this.varRequerido[key2] = true }
                }

                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2.respuesta.id)
                // eslint-disable-next-line eqeqeq
                if (value2.respuesta.mixto == 1) {
                  this.formData.append(`detalles[${this.contador}][mixto]`, value2.mixtoValue)
                } else {
                  this.formData.append(`detalles[${this.contador}][mixto]`, '')
                }
                this.contador += 1
              }
            })
            break

          case 'archivo':
            Object.entries(this.archivo).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (pregunta.respuestax !== null) {
                    this.varRequerido[key2] = true
                  } else if (value2 === '' || value2 === null) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }

                if (pregunta.respuestax === null || pregunta.respuestax === '') {
                  this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                  this.formData.append(`detalles[${this.contador}][respuesta]`, value2)
                  this.contador += 1
                }
              }
            })
            break

          case 'fecha':
            Object.entries(this.fecha).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2 === '' || value2 === null) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }

                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2)
                this.contador += 1
              }
            })
            break

          case 'hora':
            Object.entries(this.hora).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // verificaremos si la pregunta pasa la validación
                if (pregunta.requerido === 1 && condicionSeccion === false) {
                  if (value2 === '' || value2 === null) {
                    this.varRequerido[key2] = false
                    this.varPasamos = false
                  } else { this.varRequerido[key2] = true }
                }

                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key2)
                this.formData.append(`detalles[${this.contador}][respuesta]`, value2)
                this.contador += 1
              }
            })
            break

          case 'compuesta': // NOS QUEDAMOS EN VALIDAR COMPUESTA EN CASO CONDICIONAL ES TRUE
            Object.entries(this.compuesta).forEach(([key2, value2]) => {
            // eslint-disable-next-line eqeqeq
              if (key2 == pregunta.id) {
                // pasamos a validar

                if (!this.validacionPreguntaCompuesta(key2, value2, pregunta, condicionSeccion)) {
                  this.varPasamos = false
                } else {
                  this.varRequerido[key2] = true
                }
                if (this.varPasamos) {
                  value2.forEach((value3, index) => { // como una pregunta tipo COMPUESTA tiene filas y estas filas tienen preguntas recorremos la fila
                  // recorremos cada fila de la pregunta tipo COMPUESTA
                    if (!(Object.entries(value3).length === 0)) {
                      Object.entries(value3).forEach(([key4, value4]) => {
                        switch (key4) {
                          case 'respuesta_corta':
                          case 'parrafo':
                          case 'fecha':
                          case 'hora':
                          case 'archivo':
                          case 'varias_opciones':
                            if (!(Object.entries(value4).length === 0)) {
                              Object.entries(value4).forEach(([key5, value5]) => {
                                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key5)
                                this.formData.append(`detalles[${this.contador}][compuesta]`, 1)
                                this.formData.append(`detalles[${this.contador}][respuesta]`, value5)
                                this.formData.append(`detalles[${this.contador}][respuesta_id]`, this.respuestasIdCompuesta[key5][index] === undefined ? null : this.respuestasIdCompuesta[key5][index])

                                this.contador += 1
                              })
                            }
                            break

                          case 'mixto_varias_opciones':
                            if (!(Object.entries(value4).length === 0)) {
                              Object.entries(value4).forEach(([key5, value5]) => {
                                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key5)
                                this.formData.append(`detalles[${this.contador}][respuesta]`, value5.respuesta.id)
                                this.formData.append(`detalles[${this.contador}][compuesta]`, 1)
                                this.formData.append(`detalles[${this.contador}][respuesta_id]`, this.respuestasIdCompuesta[key5][index] === undefined ? null : this.respuestasIdCompuesta[key5][index])
                                if (value5.respuesta.mixto === 1) {
                                  this.formData.append(`detalles[${this.contador}][mixto]`, value5.mixtoValue)
                                } else {
                                  this.formData.append(`detalles[${this.contador}][mixto]`, '')
                                }
                                this.contador += 1
                              })
                            }
                            break

                          case 'casillas':
                            if (!(Object.entries(value4).length === 0)) {
                              Object.entries(value4).forEach(([key6, value6]) => {
                                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key6)
                                this.formData.append(`detalles[${this.contador}][compuesta]`, 1)
                                this.formData.append(`detalles[${this.contador}][respuesta_id]`, this.respuestasIdCompuesta[key6][index] ? null : this.respuestasIdCompuesta[key6][index])
                                value6.forEach(value7 => {
                                  this.formData.append(`detalles[${this.contador}][respuesta][]`, value7)
                                })
                                this.contador += 1
                              })
                            }
                            break

                          case 'mixto_casillas':
                            if (!(Object.entries(value4).length === 0)) {
                              Object.entries(value4).forEach(([key8, value8]) => {
                                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key8)
                                this.formData.append(`detalles[${this.contador}][compuesta]`, 1)
                                this.formData.append(`detalles[${this.contador}][respuesta_id]`, this.respuestasIdCompuesta[key8][index] === undefined ? null : this.respuestasIdCompuesta[key8][index])
                                Object.entries(value8).forEach(([key9, value9]) => {
                                // eslint-disable-next-line eqeqeq
                                  if (value9.checked == 1) {
                                    this.formData.append(`detalles[${this.contador}][respuesta][]`, key9)
                                    this.formData.append(`detalles[${this.contador}][mixto][]`, value9.mixtoValue)
                                  }
                                })

                                this.contador += 1
                              })
                            }
                            break

                          case 'desplegable':
                            if (!(Object.entries(value4).length === 0)) {
                              Object.entries(value4).forEach(([key10, value10]) => {
                                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key10)
                                this.formData.append(`detalles[${this.contador}][compuesta]`, 1)
                                this.formData.append(`detalles[${this.contador}][respuesta]`, value10)
                                this.formData.append(`detalles[${this.contador}][respuesta_id]`, this.respuestasIdCompuesta[key10][index] === undefined ? null : this.respuestasIdCompuesta[key10][index])
                                this.contador += 1
                              })
                            }
                            break

                          case 'mixto_desplegable':
                            if (!(Object.entries(value4).length === 0)) {
                              Object.entries(value4).forEach(([key11, value11]) => {
                                this.formData.append(`detalles[${this.contador}][pregunta_id]`, key11)
                                this.formData.append(`detalles[${this.contador}][compuesta]`, 1)
                                this.formData.append(`detalles[${this.contador}][respuesta]`, value11.respuesta.id)
                                this.formData.append(`detalles[${this.contador}][respuesta_id]`, this.respuestasIdCompuesta[key11][index] === undefined ? null : this.respuestasIdCompuesta[key11][index])
                                // eslint-disable-next-line eqeqeq
                                if (value11.respuesta.mixto == 1) {
                                  this.formData.append(`detalles[${this.contador}][mixto]`, value11.mixtoValue)
                                } else {
                                  this.formData.append(`detalles[${this.contador}][mixto]`, '')
                                }

                                this.contador += 1
                              })
                            }
                            break

                          default:
                            break
                        }
                      })
                    }
                  })
                }
              }
            })

            break
          default:
            break
        }
      })

      return this.varPasamos
    },

    validacionPreguntaCompuesta(preCompID, filasPreguntaCompuesta, pregunta, condicionSeccion) {
      let varCompuestaPasamos = true

      filasPreguntaCompuesta.forEach((value, index) => {
        Object.entries(value).forEach(([key2, value2]) => {
          switch (key2) {
            case 'respuesta_corta':
            case 'parrafo':
            case 'fecha':
            case 'hora':
            case 'varias_opciones':

              Object.entries(value2).forEach(([key3, value3]) => {
                if (pregunta.compuesta.find(val => val.id === parseInt(key3, 10)).requerido === 1 && condicionSeccion === false) {
                  if (value3 === '' || value3 === null) {
                    this.varCompuestaRequerido[preCompID][index][key3] = false
                    varCompuestaPasamos = false
                  } else {
                    this.varCompuestaRequerido[preCompID][index][key3] = true
                  }
                }
              })
              break

            case 'mixto_varias_opciones':
              Object.entries(value2).forEach(([key3, value3]) => {
                if (pregunta.compuesta.find(val => val.id === parseInt(key3, 10)).requerido === 1 && condicionSeccion === false) {
                  if (value3.respuesta.respuesta === undefined) {
                    this.varCompuestaRequerido[preCompID][index][key3] = false
                    varCompuestaPasamos = false
                  } else if (value3.respuesta.mixto === 1) {
                    if (value3.mixtoValue === '' || value3.mixtoValue === null) {
                      this.varCompuestaRequerido[preCompID][index][key3] = false
                      varCompuestaPasamos = false
                    } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                  } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                }
              })

              break

            case 'casillas':
              Object.entries(value2).forEach(([key3, value3]) => {
                if (pregunta.compuesta.find(val => val.id === parseInt(key3, 10)).requerido === 1 && condicionSeccion === false) {
                  if (value3.length === 0) {
                    this.varCompuestaRequerido[preCompID][index][key3] = false
                    varCompuestaPasamos = false
                  } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                }
              })
              break

            case 'mixto_casillas':
              Object.entries(value2).forEach(([key3, value3]) => {
                if (pregunta.compuesta.find(val => val.id === parseInt(key3, 10)).requerido === 1 && condicionSeccion === false) {
                  let pasa = false
                  let pasa2 = true
                  const preguntaCompuesta = pregunta.compuesta.find(val => val.id === parseInt(key3, 10))
                  Object.entries(value3).forEach(([key4, value4]) => {
                  // eslint-disable-next-line eqeqeq
                    if (value4.checked == 1 && preguntaCompuesta.detalles.find(val => val.id == parseInt(key4, 10)).mixto == 0) {
                      pasa = true
                    // eslint-disable-next-line eqeqeq
                    } else if (value4.checked == 1 && preguntaCompuesta.detalles.find(val => val.id == parseInt(key4, 10)).mixto == 1) {
                      if (value4.mixtoValue !== '') {
                        pasa = true
                      } else {
                        pasa = false
                        pasa2 = false
                      }
                    }
                  })

                  if (pasa === false || pasa2 === false) {
                    this.varCompuestaRequerido[preCompID][index][key3] = false
                    varCompuestaPasamos = false
                  } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                }
              })
              break

            case 'desplegable':

              Object.entries(value2).forEach(([key3, value3]) => {
                if (pregunta.compuesta.find(val => val.id === parseInt(key3, 10)).requerido === 1 && condicionSeccion === false) {
                  if (value3 === '' || value3 === null) {
                    this.varCompuestaRequerido[preCompID][index][key3] = false
                    varCompuestaPasamos = false
                  } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                }
              })
              break

            case 'mixto_desplegable':
              Object.entries(value2).forEach(([key3, value3]) => {
                if (pregunta.compuesta.find(val => val.id === parseInt(key3, 10)).requerido === 1 && condicionSeccion === false) {
                  if (value3.respuesta.respuesta === undefined) {
                    this.varCompuestaRequerido[preCompID][index][key3] = false
                    varCompuestaPasamos = false
                  // eslint-disable-next-line eqeqeq
                  } else if (value3.respuesta.mixto == 1) {
                    if (value3.mixtoValue === '' || value3.mixtoValue === null) {
                      this.varCompuestaRequerido[preCompID][index][key3] = false
                      varCompuestaPasamos = false
                    } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                  } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                }
              })
              break

            case 'archivo':

              Object.entries(value2).forEach(([key3, value3]) => {
                if (pregunta.compuesta.find(val => val.id === parseInt(key3, 10)).requerido === 1 && condicionSeccion === false) {
                  const aliasPreguntaComp = pregunta.compuesta.find(val => val.id === parseInt(key3, 10))

                  if (aliasPreguntaComp.requerido === 1 && condicionSeccion === false) {
                    if (store.state.formularioModule.autentificacion) {
                      if (aliasPreguntaComp.respuestax.length !== 0) {
                        if (aliasPreguntaComp.respuestax[index] === undefined) {
                          if (value3 === '' || value3 === null) {
                            this.varCompuestaRequerido[preCompID][index][key3] = false
                            varCompuestaPasamos = false
                          } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                        } else {
                          this.varCompuestaRequerido[preCompID][index][key3] = true
                        }
                      } else if (value3 === '' || value3 === null) {
                        this.varCompuestaRequerido[preCompID][index][key3] = false
                        varCompuestaPasamos = false
                      } else { this.varCompuestaRequerido[preCompID][index][key3] = true }
                    }
                  }
                }
              })

              break

            default:
              break
          }
        })
      })

      return varCompuestaPasamos
    },
    EnviarFormulario() {
      if (this.vistaPrevia) {
        this.$emit('fomularioCompleto')
        return
      }

      if (store.state.formularioModule.autentificacion === 0) {
        // recorremos todos los tabs del formulario para evitar duplicidad
        if (this.envioFormularioanonimo === false) {
          this.envioFormularioanonimo = true
          this.formData = new FormData()
          this.contador = 0
          this.seccionActual = 0
          this.$refs.refFormWizard.activeTabIndex = 0
          this.$refs.refFormWizard.reset()

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.$refs.refFormWizard.tabs.length; index++) {
            this.$refs.refFormWizard.nextTab()
          }
          return
        }

        store.dispatch('formularioModule/postRespuestaAnonimo', this.formData).then(() => {
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡ALGO SALIO MAL, COMUNICAR AL ADMINISTRADOR!',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
      }

      if (this.estadoFormulario) {
        return
      }

      this.$emit('fomularioCompleto')
    },
    limpiarPreguntasSeccion() {
      this.seccionesFormulario[this.seccionActual].preguntas.forEach(pregunta => {
        switch (pregunta.tipo) {
          case 'respuesta_corta': this.respuestaCorta[pregunta.id] = ''; break
          case 'parrafo': this.parrafo[pregunta.id] = ''; break
          case 'varias_opciones': this.variasOpciones[pregunta.id] = null; break
          case 'mixto_varias_opciones':
            this.mixtoVariasOpciones[pregunta.id] = { respuesta: { mixto: 0 }, mixtoValue: '' }
            break
          case 'casillas': this.casillas[pregunta.id] = []; break
          case 'mixto_casillas':
            this.$set(this.mixtoCasillas, pregunta.id, {})
            pregunta.detalles.forEach(value => {
              this.$set(this.mixtoCasillas[pregunta.id], value.id, { checked: '0', mixtoValue: '' })
            })
            break

          case 'desplegable': this.desplegable[pregunta.id] = null; break

          case 'mixto_desplegable': this.mixtoDesplegable[pregunta.id] = { respuesta: {}, mixtoValue: '' }; break

          case 'archivo': this.archivo[pregunta.id] = null; break

          case 'fecha': this.fecha[pregunta.id] = null; break

          case 'hora': this.hora[pregunta.id] = null; break

          case 'compuesta':
            while (this.compuesta[pregunta.id].length > 1) {
              this.compuesta[pregunta.id].splice(this.compuesta[pregunta.id].length - 1, 1)
              this.varCompuestaRequerido[pregunta.id].splice(this.compuesta[pregunta.id].length - 1, 1)
            }

            this.limpiarPreguntacompuesta(pregunta.compuesta, pregunta.id)
            break
          default:
            break
        }
      })
    },

    limpiarPreguntacompuesta(pregCompuesta, IdPG) {
      pregCompuesta.forEach(value => {
        switch (value.tipo) {
          case 'respuesta_corta': this.compuesta[IdPG][0].respuesta_corta[value.id] = ''; break
          case 'parrafo': this.compuesta[IdPG][0].parrafo[value.id] = ''; break
          case 'varias_opciones': this.compuesta[IdPG][0].varias_opciones[value.id] = null; break
          case 'mixto_varias_opciones':
            this.compuesta[IdPG][0].mixto_varias_opciones[value.id] = { respuesta: { mixto: 0 }, mixtoValue: '' }
            break
          case 'casillas': this.compuesta[IdPG][0].casillas[value.id] = []; break
          case 'mixto_casillas':
            this.$set(this.compuesta[IdPG][0].mixto_casillas, value.id, {})
            pregCompuesta.detalles.forEach(value2 => {
              this.$set(this.compuesta[IdPG][0].mixto_casillas[value.id], value2.id, { checked: '0', mixtoValue: '' })
            })
            break

          case 'desplegable': this.compuesta[IdPG][0].desplegable[value.id] = null; break

          case 'mixto_desplegable': this.compuesta[IdPG][0].mixto_desplegable[value.id] = { respuesta: {}, mixtoValue: '' }; break

          case 'archivo': this.compuesta[IdPG][0].archivo[value.id] = null; break

          case 'fecha': this.compuesta[IdPG][0].fecha[value.id] = null; break

          case 'hora': this.compuesta[IdPG][0].hora[value.id] = null; break

          default:
            break
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  </style>
<style scoped>
 .wizard-icon-container{
   background-color: red;
 }
</style>
