<template>
  <div>
    <span
      v-for="(pregunta, index) in preguntas"
      :key="index"
    >
      <b-card
        v-if="pregunta.visible === 1"
        :class="{' border-danger': !varRequerido[pregunta.id]}"
        class="px-0"
      >
        <div class="d-flex justify-content-between">
          <span class="text-default">
            <strong>{{ pregunta.pregunta }}</strong><br>
            <small>{{ pregunta.descripcion }}</small>
          </span>
          <div v-if="pregunta.tipo !== 'compuesta'">
            <small
              v-if="pregunta.requerido === 1"
              class="text-danger"
            >(*) Obligatorio</small>
            <small
              v-else
              class="text-info"
            >(opcional)</small>
          </div>
        </div>
        <br>

        <!-- respuesta_corta -->
        <div v-if="pregunta.tipo ==='respuesta_corta'">
          <b-form-input
            v-model="respuestaCorta[pregunta.id]"
            type="text"
            :disabled="estadoFormulario"
          />
        </div>

        <!-- parrafo -->
        <div v-else-if="pregunta.tipo === 'parrafo'">
          <b-form-textarea
            v-model="parrafo[pregunta.id]"
            :disabled="estadoFormulario"
          />
        </div>

        <!-- varias_opciones -->
        <div
          v-else-if="pregunta.tipo === 'varias_opciones'"
        >
          <b-form-radio
            v-for="item in pregunta.detalles"
            :key="item.id"
            v-model="variasOpciones[pregunta.id]"
            class="mb-1"
            :name="`name${pregunta.id}`"
            :value="item.id"
            :disabled="estadoFormulario"
          >
            {{ item.respuesta }}
          </b-form-radio>
        </div>

        <!-- mixto_varias_opciones -->
        <div v-else-if="pregunta.tipo === 'mixto_varias_opciones'">
          <b-form-radio
            v-for="item in pregunta.detalles"
            :key="item.id"
            v-model="mixtoVariasOpciones[pregunta.id].respuesta"
            class="mb-1"
            :name="`name${pregunta.id}`"
            :value="item"
            :disabled="estadoFormulario"
            @change="mixtoVariasOpciones[pregunta.id].mixtoValue = ''"
          >
            {{ item.respuesta }}
            <br>
            <div v-if="item.mixto == 1">
              <div v-if="mixtoVariasOpciones[pregunta.id].respuesta">
                <div v-show="mixtoVariasOpciones[pregunta.id].respuesta.id === item.id">
                  <label class="text-warning ml-1">{{ item.mixto_detalles }}</label>
                  <b-form-input
                    v-model="mixtoVariasOpciones[pregunta.id].mixtoValue"
                    class="ml-1"
                    type="text"
                    :disabled="estadoFormulario"
                  />
                </div>
              </div>
            </div>
          </b-form-radio>
        </div>

        <!-- casillas -->
        <div v-else-if="pregunta.tipo === 'casillas'">
          <b-form-group>
            <b-form-checkbox-group
              v-model="casillas[pregunta.id]"
              name="flavour-1"
              class="demo-inline-spacing"
            >
              <b-form-checkbox
                v-for="item in pregunta.detalles"
                :key="item.id"
                :value="item.id"
                class="custom-control-primary"
                :disabled="estadoFormulario"
              >
                {{ item.respuesta }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>

        <!-- mixto_casillas -->
        <div v-else-if="pregunta.tipo === 'mixto_casillas'">
          <b-form-group>
            <b-form-checkbox
              v-for="item in pregunta.detalles"
              :key="item.id"
              v-model="mixtoCasillas[pregunta.id][item.id].checked"
              value="1"
              unchecked-value="0"
              :disabled="estadoFormulario"
              class="custom-control-primary"
            >
              {{ item.respuesta }}
              <br>
              <span v-if="item.mixto == 1">
                <span v-show="mixtoCasillas[pregunta.id][item.id].checked == 1">
                  <label class="text-warning ml-1">{{ item.mixto_detalles }}</label>
                  <b-form-input
                    v-model="mixtoCasillas[pregunta.id][item.id].mixtoValue"
                    class="ml-1"
                    size="sm"
                    type="text"
                    :disabled="estadoFormulario"
                  />
                </span>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </div>

        <!-- desplegable -->
        <div v-else-if="pregunta.tipo === 'desplegable'">
          <b-form-select
            v-model="desplegable[pregunta.id]"
            :options="optionsDesplegable[pregunta.id]"
            :disabled="estadoFormulario"
          />
        </div>

        <!-- mixto_desplegable -->
        <div v-else-if="pregunta.tipo === 'mixto_desplegable'">
          <b-form-select
            v-model="mixtoDesplegable[pregunta.id].respuesta"
            :options="optionsMixtoDesplegable[pregunta.id]"
            :disabled="estadoFormulario"
          />
          <span v-if="verificarMixtoDesplegable(mixtoDesplegable[pregunta.id].respuesta, pregunta.id)">

            <label class="text-warning ml-1">{{ obtenerPreguntaMixtoDesplegable(mixtoDesplegable[pregunta.id].respuesta.id, pregunta.detalles) }}</label>
            <b-form-input
              v-model="mixtoDesplegable[pregunta.id].mixtoValue"
              class="ml-1"
              size="sm"
              type="text"
              :disabled="estadoFormulario"
            />
          </span>
        </div>

        <!-- archivo -->
        <div v-else-if="pregunta.tipo === 'archivo'">
          <b-form-file
            v-model="archivo[pregunta.id]"
            placeholder="Elige un archivo..."
            :disabled="estadoFormulario"
          />
          <span v-if="pregunta.respuestax">
            <a
              :href="pregunta.respuestax.respuesta"
              class="btn btn-sm btn-outline-success"
              target="_blank"
            >ver archivo
            </a>
          </span>
        </div>

        <!-- fecha -->
        <div v-else-if="pregunta.tipo === 'fecha'">
          <b-form-datepicker
            v-model="fecha[pregunta.id]"
            class="mb-1"
            :disabled="estadoFormulario"
          />
        </div>

        <!-- hora -->
        <div v-else-if="pregunta.tipo === 'hora'">
          <b-form-timepicker
            v-model="hora[pregunta.id]"
            locale="en"
            :disabled="estadoFormulario"
          />
        </div>

        <!-- compuesta -->
        <div v-else-if="pregunta.tipo === 'compuesta'">
          <pregunta-compuesta
            :pregunta-compuesta="pregunta.compuesta"
            :pregunta-id="pregunta.id"
          />

        </div>
        <div v-else>
          NO HAY PREGUNTA SELECCIONADA
        </div>
      </b-card>
    </span>

  </div>
</template>

<script>
import {
  BCard, BFormInput, BFormTextarea, BFormRadio, BFormCheckbox, BFormCheckboxGroup, BFormGroup,
  BFormSelect, BFormFile, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import PreguntaCompuesta from '@/views/administrador/gestion-formulario/formulario/PreguntaCompuesta.vue'

export default {
  components: {
    BCard,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BFormDatepicker,
    BFormTimepicker,
    PreguntaCompuesta,
  },
  props: {
    preguntas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mixto: '', // usado solo en mixto_deplegable
    }
  },
  computed: {
    ...mapState('formularioModule', ['estadoFormulario', 'varRequerido', 'respuestaCorta', 'parrafo', 'variasOpciones', 'mixtoVariasOpciones',
      'casillas', 'mixtoCasillas', 'desplegable', 'optionsDesplegable', 'mixtoDesplegable',
      'optionsMixtoDesplegable', 'unoMixtoDesplegable', 'archivo', 'fecha', 'hora']),
  },
  mounted() {

  },
  created() {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.preguntas.length; index++) {
      if (this.preguntas[index].visible === 1) {
        switch (this.preguntas[index].tipo) {
          case 'respuesta_corta':
            this.$set(this.respuestaCorta, this.preguntas[index].id, '')
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            // cargamos respuesta si hubiera
            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta === 'null') {
                this.respuestaCorta[this.preguntas[index].id] = ''
              } else {
                this.respuestaCorta[this.preguntas[index].id] = this.preguntas[index].respuestax.respuesta
              }
            }
            break
          case 'parrafo':
            this.$set(this.parrafo, this.preguntas[index].id, '')
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            // cargamos respuesta si hubiera
            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta === 'null') {
                this.parrafo[this.preguntas[index].id] = ''
              } else {
                this.parrafo[this.preguntas[index].id] = this.preguntas[index].respuestax.respuesta
              }
            }
            break
          case 'varias_opciones':
            this.$set(this.variasOpciones, this.preguntas[index].id, null)
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            // cargamos respuesta si hubiera
            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta !== null) {
                this.variasOpciones[this.preguntas[index].id] = this.preguntas[index].respuestax.respuesta
              }
            }
            break
          case 'mixto_varias_opciones':
            this.$set(this.mixtoVariasOpciones, this.preguntas[index].id, { respuesta: {}, mixtoValue: '' })
            this.$set(this.mixtoVariasOpciones[this.preguntas[index].id].respuesta, 'mixto', 0)
            this.$set(this.mixtoVariasOpciones[this.preguntas[index].id].respuesta, 'id', null)
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            // cargamos respuesta si hubiera
            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta !== null) {
                this.mixtoVariasOpciones[this.preguntas[index].id].respuesta = this.preguntas[index].detalles.find(val => val.id === parseInt(this.preguntas[index].respuestax.respuesta, 10))
                this.mixtoVariasOpciones[this.preguntas[index].id].mixtoValue = this.preguntas[index].respuestax.respuesta_mixto
              }
            }
            break

          case 'casillas':
            this.$set(this.casillas, this.preguntas[index].id, [])
            this.$set(this.varRequerido, this.preguntas[index].id, true)

            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta !== null) {
                this.casillas[this.preguntas[index].id] = this.preguntas[index].respuestax.respuesta.split('[~]')
              }
            }
            break

          case 'mixto_casillas':

            this.$set(this.mixtoCasillas, this.preguntas[index].id, {})
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            this.preguntas[index].detalles.forEach(value => {
              this.$set(this.mixtoCasillas[this.preguntas[index].id], value.id, { checked: '0', mixtoValue: '' })
            })
            // cargamos respuesta si hubiera
            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta) {
                const arrayMixtoCasillas = this.preguntas[index].respuestax.respuesta.split('[~]')
                const arrayValueMixtoCasillas = this.preguntas[index].respuestax.respuesta_mixto.split('[~]')
                if (arrayMixtoCasillas && arrayValueMixtoCasillas) {
                  // eslint-disable-next-line no-plusplus
                  for (let xxx = 0; xxx < arrayMixtoCasillas.length; xxx++) {
                    if (this.preguntas[index].detalles.some(val => val.id === parseInt(arrayMixtoCasillas[xxx], 10))) {
                      this.mixtoCasillas[this.preguntas[index].id][arrayMixtoCasillas[xxx]].checked = '1'
                      this.mixtoCasillas[this.preguntas[index].id][arrayMixtoCasillas[xxx]].mixtoValue = arrayValueMixtoCasillas[xxx]
                    }
                  }
                }
              }
            }
            break

          case 'desplegable':
            this.$set(this.optionsDesplegable, this.preguntas[index].id,
              this.preguntas[index].detalles.map(value => ({ value: value.id, text: `${value.respuesta}` })))
            this.$set(this.desplegable, this.preguntas[index].id, null)
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta !== 'null') {
                this.desplegable[this.preguntas[index].id] = this.preguntas[index].respuestax.respuesta
              }
            }
            break

          case 'mixto_desplegable':
            this.$set(this.optionsMixtoDesplegable, this.preguntas[index].id,
              this.preguntas[index].detalles.map(value => ({ value, text: `${value.respuesta}` })))
            this.$set(this.unoMixtoDesplegable, this.preguntas[index].id, [])
            this.preguntas[index].detalles.forEach(value => {
              if (value.mixto === 1) {
                this.unoMixtoDesplegable[this.preguntas[index].id].push(value.id)
              }
            })

            this.$set(this.mixtoDesplegable, this.preguntas[index].id, { respuesta: {}, mixtoValue: '' })
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            // cargamos respuesta si hubiera
            if (this.preguntas[index].respuestax) {
              if (this.preguntas[index].respuestax.respuesta) {
                this.mixtoDesplegable[this.preguntas[index].id].respuesta = this.preguntas[index].detalles.find(val => val.id === parseInt(this.preguntas[index].respuestax.respuesta, 10))
                this.mixtoDesplegable[this.preguntas[index].id].mixtoValue = this.preguntas[index].respuestax.respuesta_mixto
              }
            }
            break

          case 'archivo':
            this.$set(this.archivo, this.preguntas[index].id, null)
            this.$set(this.varRequerido, this.preguntas[index].id, true)

            if (this.preguntas[index].respuestax) {
              if (!this.preguntas[index].respuestax.respuesta.includes('http')) {
                this.preguntas[index].respuestax = null
              }
            }
            break

          case 'fecha':
            this.$set(this.fecha, this.preguntas[index].id, null)
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            // cargamos respuesta si hubiera

            if (this.preguntas[index].respuestax) {
              // eslint-disable-next-line no-restricted-globals
              if (!isNaN(this.preguntas[index].respuestax.respuesta)) {
                this.fecha[this.preguntas[index].id] = null
                // eslint-disable-next-line no-restricted-globals
              } else if (isNaN(Date.parse(this.preguntas[index].respuestax.respuesta))) {
                this.fecha[this.preguntas[index].id] = null
              } else {
                this.fecha[this.preguntas[index].id] = this.preguntas[index].respuestax.respuesta
              }
            }
            break

          case 'hora':
            this.$set(this.hora, this.preguntas[index].id, null)
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            // cargamos respuesta si hubiera
            if (this.preguntas[index].respuestax) {
              // eslint-disable-next-line no-restricted-globals
              if (this.preguntas[index].respuestax.respuesta.includes(':')) {
                this.hora[this.preguntas[index].id] = this.preguntas[index].respuestax.respuesta
              } else {
                this.hora[this.preguntas[index].id] = null
              }
            }
            break

          case 'compuesta':
            this.$set(this.varRequerido, this.preguntas[index].id, true)
            break

          default:
            break
        }
      }
    }
  },
  methods: {
    verificarMixtoDesplegable(respuestaSeleccionada, idPregunta) {
      // eslint-disable-next-line eqeqeq
      if (this.unoMixtoDesplegable[idPregunta].some(elem => respuestaSeleccionada.id == elem)) {
        return true
      }
      return false
    },
    obtenerPreguntaMixtoDesplegable(idOpcionSeleccionada, opciones) {
      let pregunta = ''
      opciones.forEach(value => {
        if (value.id === idOpcionSeleccionada) {
          if (value.mixto === 1) {
            pregunta = value.mixto_detalles
          }
        }
      })
      return pregunta
    },

  },

}
</script>
