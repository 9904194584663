import axios from '@axios'

export default {
  namespaced: true,
  state: {
    formularioId: null,
    autentificacion: 0,

    respuestaCorta: {}, // respuesta de las preguntas tipo 'respuesta_corta'
    parrafo: {}, // respuesta de las preguntas tipo 'parrafo'
    variasOpciones: {}, // respuesta de las preguntas tipo 'varias_opciones'
    mixtoVariasOpciones: {}, // respuesta de las preguntas tipo 'mixto_varias_opciones'
    casillas: {}, // respuesta de las preguntas tipo 'casillas'
    mixtoCasillas: {}, // respuesta de las preguntas tipo 'mixto_casillas'
    desplegable: {}, // respuesta de las preguntas tipo 'desplegable'
    optionsDesplegable: {}, // opciones de respuesta de las preguntas tipo 'desplegable'
    mixtoDesplegable: {}, // respuesta de las preguntas tipo 'mixto_desplegable'
    optionsMixtoDesplegable: {}, // opciones de respuesta de las preguntas tipo 'mixto_desplegable'
    unoMixtoDesplegable: {}, // respuestas que tiene 1 en 'mixto' de las preguntas tipo 'mixto_desplegable'
    archivo: {}, // respuesta de las preguntas tipo 'archivo'
    fecha: {}, // respuesta de las preguntas tipo 'fecha'
    hora: {}, // respuesta de las preguntas tipo 'hora'

    compuesta: {}, // respuesta de las preguntas tipo 'compuesta'
    optionsCompuestaDesplegable: {}, // opciones de respuesta de las preguntas tipo 'desplegable' dentro de una COMPUESTA
    optionsCompuestaMixtoDesplegable: {}, // opciones de respuesta de las preguntas tipo 'mixto_desplegable' dentro de una COMPUESTA
    unoEnCompuestaMixtoDesplegable: {}, // respuestas que tiene 1 en 'mixto' de las preguntas tipo 'mixto_desplegable' dentro de una COMPUESTA

    varRequerido: {},
    varCompuestaRequerido: {},

    respuestaCondicional: {},
    respuestasIdCompuesta: {}, // guardamos los id de las respuestas en caso hubiera

    estadoFormulario: false, // estado del formulario, si esta activo o no
  },
  getters: {},
  mutations: {},
  actions: {

    getFormularioAuth(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/recursos/formulario/${ctx.state.formularioId}/respuesta?usuario=${params.usuario}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFormularioAnonimo(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/recursos/formulario/${ctx.state.formularioId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postRespuestaAuth(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/recursos/formulario/${ctx.state.formularioId}/respuesta_auth`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postRespuestaAnonimo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/recursos/formulario/${ctx.state.formularioId}/respuesta`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
