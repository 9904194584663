<template>
  <div>

    <div
      v-for="(itemSup, indexSup) in compuesta[preguntaId]"
      :key="indexSup"
      ref="row"
    >
      <b-row class="border rounded p-1 mb-1">

        <b-col
          v-for="(pregunta, index) in preguntaCompuesta"
          :key="index"
          :xs="pregunta.visible === 1 ? 12 : 0"
          :sm="pregunta.visible === 1 ? 12 : 0"
          :lg="pregunta.visible === 1 ? 6 : 0"
          :xl="pregunta.visible === 1 ? 6 : 0"
          class="mb-1"
        >
          <div
            v-if="pregunta.visible === 1"
            :class="{'p-1 border-danger rounded': !varCompuestaRequerido[preguntaId][indexSup][pregunta.id]}"
          >
            <small
              v-if="pregunta.requerido === 1"
              class="text-danger mr-1"
            >(*)</small>
            <span><strong>{{ pregunta.pregunta }}</strong></span>

            <!-- respuesta_corta -->
            <div v-if="pregunta.tipo ==='respuesta_corta'">

              <b-form-input
                v-model="compuesta[preguntaId][indexSup].respuesta_corta[`${pregunta.id}`]"
                type="text"
                :disabled="estadoFormulario"
              />
            </div>

            <!-- parrafo -->
            <div v-else-if="pregunta.tipo === 'parrafo'">

              <b-form-textarea
                v-model="compuesta[preguntaId][indexSup].parrafo[`${pregunta.id}`]"
                :disabled="estadoFormulario"
              />
            </div>

            <!-- varias_opciones -->
            <div
              v-else-if="pregunta.tipo === 'varias_opciones'"
            >

              <b-form-radio
                v-for="item in pregunta.detalles"
                :key="item.id"
                v-model="compuesta[preguntaId][indexSup].varias_opciones[`${pregunta.id}`]"
                class="mb-1"
                :name="`pcname${pregunta.id}${indexSup}`"
                :value="item.id"
                :disabled="estadoFormulario"
              >
                {{ item.respuesta }}
              </b-form-radio>
            </div>

            <!-- mixto_varias_opciones -->
            <div v-else-if="pregunta.tipo === 'mixto_varias_opciones'">

              <b-form-radio
                v-for="item in pregunta.detalles"
                :key="item.id"
                v-model="compuesta[preguntaId][indexSup].mixto_varias_opciones[`${pregunta.id}`].respuesta"
                class="mb-1"
                :name="`name${pregunta.id}${indexSup}`"
                :value="item"
                :disabled="estadoFormulario"
                @change="compuesta[preguntaId][indexSup].mixto_varias_opciones[`${pregunta.id}`].mixtoValue = ''"
              >
                {{ item.respuesta }} <br>
                <div v-if="item.mixto == 1">
                  <div v-if="compuesta[preguntaId][indexSup].mixto_varias_opciones[`${pregunta.id}`].respuesta">
                    <div v-show="compuesta[preguntaId][indexSup].mixto_varias_opciones[`${pregunta.id}`].respuesta.id === item.id">
                      <label class="text-warning">{{ item.mixto_detalles }}</label>
                      <b-form-input
                        v-model="compuesta[preguntaId][indexSup].mixto_varias_opciones[`${pregunta.id}`].mixtoValue"
                        size="sm"
                        type="text"
                        :disabled="estadoFormulario"
                      />
                    </div>
                  </div>
                </div>
              </b-form-radio>
            </div>

            <!-- casillas -->
            <div v-else-if="pregunta.tipo === 'casillas'">
              <b-form-group>
                <b-form-checkbox-group
                  v-model="compuesta[preguntaId][indexSup].casillas[`${pregunta.id}`]"
                  name="flavour-1"
                  class="demo-inline-spacing"
                >
                  <b-form-checkbox
                    v-for="item in pregunta.detalles"
                    :key="item.id"
                    :value="item.id"
                    :disabled="estadoFormulario"
                    class="custom-control-primary"
                  >
                    {{ item.respuesta }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>

            <!-- mixto_casillas -->
            <div v-else-if="pregunta.tipo === 'mixto_casillas'">
              <b-form-group>
                <b-form-checkbox
                  v-for="item in pregunta.detalles"
                  :key="item.id"
                  v-model="compuesta[preguntaId][indexSup].mixto_casillas[pregunta.id][item.id].checked"
                  value="1"
                  :disabled="estadoFormulario"
                  unchecked-value="0"
                  class="custom-control-primary"
                >
                  {{ item.respuesta }}
                  <br>
                  <span v-if="item.mixto == 1">
                    <span v-show="compuesta[preguntaId][indexSup].mixto_casillas[pregunta.id][item.id].checked == 1">
                      <label class="text-warning">{{ item.mixto_detalles }}</label>
                      <b-form-input
                        v-model="compuesta[preguntaId][indexSup].mixto_casillas[pregunta.id][item.id].mixtoValue"
                        size="sm"
                        :disabled="estadoFormulario"
                        type="text"
                      />
                    </span>
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </div>

            <!-- desplegable -->
            <div v-else-if="pregunta.tipo === 'desplegable'">
              <b-form-select
                v-model="compuesta[preguntaId][indexSup].desplegable[`${pregunta.id}`]"
                :options="optionsCompuestaDesplegable[pregunta.id]"
                :disabled="estadoFormulario"
              />
            </div>

            <!-- mixto_desplegable -->
            <div v-else-if="pregunta.tipo === 'mixto_desplegable'">
              <b-form-select
                v-model="compuesta[preguntaId][indexSup].mixto_desplegable[`${pregunta.id}`].respuesta"
                :options="optionsCompuestaMixtoDesplegable[pregunta.id]"
                :disabled="estadoFormulario"
              />
              <span v-if="verificarMixtoDesplegable(compuesta[preguntaId][indexSup].mixto_desplegable[`${pregunta.id}`].respuesta, pregunta.id)">
                <label class="text-warning">{{ obtenerPreguntaMixtoDesplegable(compuesta[preguntaId][indexSup].mixto_desplegable[`${pregunta.id}`].respuesta.id, pregunta.detalles) }}</label>
                <b-form-input
                  v-model="compuesta[preguntaId][indexSup].mixto_desplegable[`${pregunta.id}`].mixtoValue"
                  size="sm"
                  type="text"
                  :disabled="estadoFormulario"
                />
              </span>
            </div>

            <!-- archivo -->
            <div v-else-if="pregunta.tipo === 'archivo'">

              <b-form-file
                v-model="compuesta[preguntaId][indexSup].archivo[`${pregunta.id}`]"
                placeholder="Elige un archivo..."
                drop-placeholder="Suelte el archivo aquí..."
                :disabled="estadoFormulario"
              />
              <div v-if="autentificacion">
                <a
                  v-if="pregunta.respuestax[indexSup]"
                  :href="pregunta.respuestax[indexSup].respuesta"
                  class="btn btn-sm btn-outline-success"
                  target="_blank"
                >ver archivo
                </a>
              </div>

            </div>

            <!-- hora -->
            <div v-else-if="pregunta.tipo === 'hora'">

              <b-form-timepicker
                v-model="compuesta[preguntaId][indexSup].hora[`${pregunta.id}`]"
                locale="en"
                :disabled="estadoFormulario"
              />
            </div>

            <!-- fecha -->
            <div v-else-if="pregunta.tipo === 'fecha'">
              <b-form-datepicker
                v-model="compuesta[preguntaId][indexSup].fecha[`${pregunta.id}`]"
                class="mb-1"
                :disabled="estadoFormulario"
              />
            </div>

            <div v-else>
              NO HAY PREGUNTA SELECCIONADA
            </div>
          </div>
        </b-col>

      </b-row>
      <span v-if="(compuesta[preguntaId].length-1) === indexSup">
        <div class="d-flex justify-content-between">
          <b-button
            size="sm"
            class="px-3"
            variant="gradient-success"
            @click="addRow(indexSup + 1)"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            /><span> adicionar fila</span>
          </b-button>
          <b-button
            v-if="(0 !== indexSup)"
            variant="gradient-danger"
            size="sm"
            class="px-2"
            @click="deleteRow(indexSup)"
          >
            <span>quitar última fila</span>
            <feather-icon
              icon="XIcon"
              size="16"
            />
          </b-button>
        </div>
      </span>

    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormDatepicker, BFormCheckbox, BFormSelect, BFormTimepicker,
  BButton, BFormRadio, BFormGroup, BFormCheckboxGroup, BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormDatepicker,
    BButton,
    BFormRadio,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    BFormFile,
    FeatherIcon,
    BFormTimepicker,
  },

  props: {
    preguntaCompuesta: {
      type: Array,
      default: () => [],
    },
    preguntaId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mixto: '', // usado solo en mixto_deplegable
      usuario_id: 300,
      respuestasX: [],
    }
  },
  computed: {
    ...mapState('formularioModule', ['autentificacion', 'estadoFormulario', 'respuestasIdCompuesta', 'varCompuestaRequerido', 'compuesta', 'optionsCompuestaDesplegable', 'optionsCompuestaMixtoDesplegable', 'unoEnCompuestaMixtoDesplegable']),

  },

  created() {
    this.$set(this.compuesta, this.preguntaId, [{}])
    this.$set(this.varCompuestaRequerido, this.preguntaId, [{}])

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.preguntaCompuesta.length; index++) {
      if (this.preguntaCompuesta[index].visible === 1) {
        switch (this.preguntaCompuesta[index].tipo) {
          case 'respuesta_corta':
            if (this.compuesta[this.preguntaId][0].respuesta_corta === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'respuesta_corta', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].respuesta_corta, this.preguntaCompuesta[index].id, '')
            break

          case 'parrafo':
            if (this.compuesta[this.preguntaId][0].parrafo === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'parrafo', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].parrafo, this.preguntaCompuesta[index].id, '')
            break

          case 'varias_opciones':
            if (this.compuesta[this.preguntaId][0].varias_opciones === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'varias_opciones', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].varias_opciones, this.preguntaCompuesta[index].id, null)
            break

          case 'mixto_varias_opciones':
            if (this.compuesta[this.preguntaId][0].mixto_varias_opciones === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'mixto_varias_opciones', {})
            }

            this.$set(this.compuesta[this.preguntaId][0].mixto_varias_opciones, this.preguntaCompuesta[index].id, { respuesta: {}, mixtoValue: '' })
            this.$set(this.compuesta[this.preguntaId][0].mixto_varias_opciones[this.preguntaCompuesta[index].id].respuesta, 'mixto', 0)
            this.$set(this.compuesta[this.preguntaId][0].mixto_varias_opciones[this.preguntaCompuesta[index].id].respuesta, 'id', null) // esta fila se añadio el 25-11
            break

          case 'casillas':
            if (this.compuesta[this.preguntaId][0].casillas === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'casillas', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].casillas, this.preguntaCompuesta[index].id, [])

            break

          case 'mixto_casillas':
            if (this.compuesta[this.preguntaId][0].mixto_casillas === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'mixto_casillas', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].mixto_casillas, this.preguntaCompuesta[index].id, {})
            this.preguntaCompuesta[index].detalles.forEach(value => {
              this.$set(this.compuesta[this.preguntaId][0].mixto_casillas[this.preguntaCompuesta[index].id], value.id, { checked: '0', mixtoValue: '' })
            })
            break

          case 'desplegable':
            this.$set(this.optionsCompuestaDesplegable, this.preguntaCompuesta[index].id,
              this.preguntaCompuesta[index].detalles.map(value => ({ value: value.id, text: `${value.respuesta}` })))
            if (this.compuesta[this.preguntaId][0].desplegable === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'desplegable', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].desplegable, this.preguntaCompuesta[index].id, null)
            break

          case 'mixto_desplegable':
            if (this.compuesta[this.preguntaId][0].mixto_desplegable === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'mixto_desplegable', {})
            }
            // Llenamos las opciones de los mixtoDeplegables de una preg. compuesta
            this.$set(this.optionsCompuestaMixtoDesplegable, this.preguntaCompuesta[index].id,
              this.preguntaCompuesta[index].detalles.map(value => ({ value, text: `${value.respuesta}` })))

            // guardamos las respuestas(en un arreglo) que tienen mixto=1 para luego compararlos
            this.$set(this.unoEnCompuestaMixtoDesplegable, this.preguntaCompuesta[index].id, [])
            this.preguntaCompuesta[index].detalles.forEach(value => {
              if (value.mixto === 1) {
                this.unoEnCompuestaMixtoDesplegable[this.preguntaCompuesta[index].id].push(value.id)
              }
            })

            this.$set(this.compuesta[this.preguntaId][0].mixto_desplegable, this.preguntaCompuesta[index].id, { respuesta: {}, mixtoValue: '' })
            break

          case 'archivo':
            if (this.compuesta[this.preguntaId][0].archivo === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'archivo', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].archivo, this.preguntaCompuesta[index].id, null)
            // en caso se edito y se cambio a pregunta tipo archivo
            // verificamos si existe una ruta de archivo
            if (this.preguntaCompuesta[index].respuestax) {
              this.preguntaCompuesta[index].respuestax.forEach((val, indexCompArch) => {
                if (!this.preguntaCompuesta[index].respuestax[indexCompArch].respuesta.includes('http')) {
                  this.preguntaCompuesta[index].respuestax[indexCompArch] = null
                }
              })
            }
            break

          case 'fecha':
            if (this.compuesta[this.preguntaId][0].fecha === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'fecha', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].fecha, this.preguntaCompuesta[index].id, null)
            // en caso se edito y se cambio a pregunta tipo fecha
            // verificamos si existe una ruta de fecha
            // eslint-disable-next-line no-restricted-globals

            if (this.preguntaCompuesta[index].respuestax) {
              this.preguntaCompuesta[index].respuestax.forEach((val, indexCompFecha) => {
              // eslint-disable-next-line no-restricted-globals
                if (!isNaN(this.preguntaCompuesta[index].respuestax[indexCompFecha].respuesta)) {
                  this.preguntaCompuesta[index].respuestax[indexCompFecha].respuesta = null
                  // eslint-disable-next-line no-restricted-globals
                } else if (isNaN(Date.parse(this.preguntaCompuesta[index].respuestax[indexCompFecha].respuesta))) {
                  this.preguntaCompuesta[index].respuestax[indexCompFecha].respuesta = null
                }
              })
            }
            break

          case 'hora':
            if (this.compuesta[this.preguntaId][0].hora === undefined) {
              this.$set(this.compuesta[this.preguntaId][0], 'hora', {})
            }
            this.$set(this.compuesta[this.preguntaId][0].hora, this.preguntaCompuesta[index].id, null)
            // en caso se edito y se cambio a pregunta tipo archivo
            // verificamos si existe una ruta de archivo
            if (this.preguntaCompuesta[index].respuestax) {
              this.preguntaCompuesta[index].respuestax.forEach((val, indexCompHora) => {
                if (!this.preguntaCompuesta[index].respuestax[indexCompHora].respuesta.includes(':')) {
                  this.preguntaCompuesta[index].respuestax[indexCompHora].respuesta = null
                }
              })
            }
            break

          default:

            break
        }
        this.$set(this.varCompuestaRequerido[this.preguntaId][0], this.preguntaCompuesta[index].id, true)
      }
    }

    // recuperamos las respuestas de la pregunta tipo compuesta
    let respuestas = 0
    this.preguntaCompuesta.forEach(value => {
      if (value.visible === 1 && value.respuestax) {
        respuestas = Object.keys(value.respuestax).length
      }
    })

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < respuestas - 1; index++) {
      this.addRow(index + 1)
    }
  },
  mounted() {
    this.cargarRespuestas()
  },
  methods: {
    addRow(indexSup) {
      this.compuesta[this.preguntaId].push(this.nuevoObjeto(indexSup))
    },

    deleteRow(indexSup) {
      this.compuesta[this.preguntaId].splice(indexSup, 1)
      this.varCompuestaRequerido[this.preguntaId].splice(indexSup, 1)
    },

    verificarMixtoDesplegable(respuestaSeleccionada, idPregunta) {
      // eslint-disable-next-line eqeqeq
      if (this.unoEnCompuestaMixtoDesplegable[idPregunta].some(elem => respuestaSeleccionada.id == elem)) {
        return true
      }
      return false
    },
    obtenerPreguntaMixtoDesplegable(idOpcionSeleccionada, opciones) {
      let pregunta = ''
      opciones.forEach(value => {
        if (value.id === idOpcionSeleccionada) {
          if (value.mixto === 1) {
            pregunta = value.mixto_detalles
          }
        }
      })
      return pregunta
    },

    nuevoObjeto(indexSup) {
      const nuevoObjeto = {}
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.preguntaCompuesta.length; index++) {
        if (this.preguntaCompuesta[index].visible === 1) {
          switch (this.preguntaCompuesta[index].tipo) {
            case 'respuesta_corta':
              if (nuevoObjeto.respuesta_corta === undefined) {
                nuevoObjeto.respuesta_corta = {}
              }
              nuevoObjeto.respuesta_corta[this.preguntaCompuesta[index].id] = ''
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break
            case 'parrafo':
              if (nuevoObjeto.parrafo === undefined) {
                nuevoObjeto.parrafo = {}
              }
              nuevoObjeto.parrafo[this.preguntaCompuesta[index].id] = ''
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)
              break

            case 'varias_opciones':
              if (nuevoObjeto.varias_opciones === undefined) {
                nuevoObjeto.varias_opciones = {}
              }
              nuevoObjeto.varias_opciones[this.preguntaCompuesta[index].id] = null
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)
              break

            case 'mixto_varias_opciones': // ESTAMOS CORRIGIENDO ESTO LUEGO TOCARA MIXTO_CASILLAS Y MIXTO_DESPLEGABLE

              if (nuevoObjeto.mixto_varias_opciones === undefined) {
                nuevoObjeto.mixto_varias_opciones = {}
              }
              this.$set(nuevoObjeto.mixto_varias_opciones, this.preguntaCompuesta[index].id, { respuesta: {}, mixtoValue: '' })
              this.$set(nuevoObjeto.mixto_varias_opciones[this.preguntaCompuesta[index].id].respuesta, 'mixto', 0)
              this.$set(nuevoObjeto.mixto_varias_opciones[this.preguntaCompuesta[index].id].respuesta, 'id', null) // se añadio el 25-11
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            case 'casillas':
              if (nuevoObjeto.casillas === undefined) {
                nuevoObjeto.casillas = {}
              }
              nuevoObjeto.casillas[this.preguntaCompuesta[index].id] = []
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            case 'mixto_casillas':

              if (nuevoObjeto.mixto_casillas === undefined) {
                nuevoObjeto.mixto_casillas = {}
              }
              this.$set(nuevoObjeto.mixto_casillas, this.preguntaCompuesta[index].id, {})
              this.preguntaCompuesta[index].detalles.forEach(value => {
                this.$set(nuevoObjeto.mixto_casillas[this.preguntaCompuesta[index].id], value.id, { checked: '0', mixtoValue: '' })
              })
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            case 'desplegable':
              if (nuevoObjeto.desplegable === undefined) {
                nuevoObjeto.desplegable = {}
              }
              nuevoObjeto.desplegable[this.preguntaCompuesta[index].id] = null
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            case 'mixto_desplegable':

              if (nuevoObjeto.mixto_desplegable === undefined) {
                nuevoObjeto.mixto_desplegable = {}
              }

              nuevoObjeto.mixto_desplegable[this.preguntaCompuesta[index].id] = { respuesta: {}, mixtoValue: '' }
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            case 'archivo':
              if (nuevoObjeto.archivo === undefined) {
                nuevoObjeto.archivo = {}
              }
              nuevoObjeto.archivo[this.preguntaCompuesta[index].id] = null
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            case 'fecha':
              if (nuevoObjeto.fecha === undefined) {
                nuevoObjeto.fecha = {}
              }
              nuevoObjeto.fecha[this.preguntaCompuesta[index].id] = null
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            case 'hora':
              if (nuevoObjeto.hora === undefined) {
                nuevoObjeto.hora = {}
              }
              nuevoObjeto.hora[this.preguntaCompuesta[index].id] = null
              // variable validador
              if (this.varCompuestaRequerido[this.preguntaId][indexSup] === undefined) {
                this.varCompuestaRequerido[this.preguntaId].push({ })
              }
              this.$set(this.varCompuestaRequerido[this.preguntaId][indexSup], [this.preguntaCompuesta[index].id], true)

              break

            default:

              break
          }
        }
      }
      return nuevoObjeto
    },
    cargarRespuestas() {
      let i = 0

      this.preguntaCompuesta.forEach(pregunta => {
        this.respuestasX = {}
        this.respuestasX = pregunta.respuestax ? pregunta.respuestax : {}
        i = 0
        if (this.respuestasIdCompuesta[pregunta.id] === undefined) {
          this.respuestasIdCompuesta[pregunta.id] = []
        }
        if (Object.keys(this.respuestasX).length !== 0) { // pregunto si hay respuestas
          switch (pregunta.tipo) {
            case 'respuesta_corta':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].respuesta_corta[pregunta.id] = value.respuesta
                i += 1
                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'parrafo':

              // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].parrafo[pregunta.id] = value.respuesta
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'varias_opciones':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].varias_opciones[pregunta.id] = value.respuesta
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'mixto_varias_opciones':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].mixto_varias_opciones[pregunta.id].respuesta = pregunta.detalles.find(val => val.id === parseInt(value.respuesta, 10))
                this.compuesta[this.preguntaId][i].mixto_varias_opciones[pregunta.id].mixtoValue = value.respuesta_mixto
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'casillas':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].casillas[pregunta.id] = value.respuesta.split('[~]')
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'mixto_casillas':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                const arrayMixtoCasillas = value.respuesta.split('[~]')
                const arrayValueMixtoCasillas = value.respuesta_mixto.split('[~]')

                // eslint-disable-next-line no-plusplus
                for (let y = 0; y < arrayMixtoCasillas.length; y++) {
                  this.compuesta[this.preguntaId][i].mixto_casillas[pregunta.id][arrayMixtoCasillas[y]].checked = '1'
                  this.compuesta[this.preguntaId][i].mixto_casillas[pregunta.id][arrayMixtoCasillas[y]].mixtoValue = arrayValueMixtoCasillas[y]
                }
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'desplegable':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].desplegable[pregunta.id] = value.respuesta
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'mixto_desplegable':
              // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].mixto_desplegable[pregunta.id].respuesta = pregunta.detalles.find(val => val.id === parseInt(value.respuesta, 10))
                this.compuesta[this.preguntaId][i].mixto_desplegable[pregunta.id].mixtoValue = value.respuesta_mixto
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'archivo':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                if (value) {
                  this.respuestasIdCompuesta[pregunta.id].push(value.id)
                }
                i += 1
              })

              break

            case 'fecha':

              // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].fecha[pregunta.id] = value.respuesta
                i += 1

                // seteamos respuestas id de las preguntas compuestas en caso hubiera
                this.respuestasIdCompuesta[pregunta.id].push(value.id)
              })
              break

            case 'hora':
            // eslint-disable-next-line no-unused-vars
              Object.entries(this.respuestasX).forEach(([key, value]) => {
                this.compuesta[this.preguntaId][i].hora[pregunta.id] = value.respuesta
                i += 1

                if (value) {
                  this.respuestasIdCompuesta[pregunta.id].push(value.id)
                }
                // seteamos respuestas id de las preguntas compuestas en caso hubiera
              })
              break

            default:
              break
          }
        }
      })
    },

  },

}

</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
