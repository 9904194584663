<template>
  <div>
    <b-modal
      id="modal_obtener_enlace"
      ok-only
      size="md"
      header-bg-variant="warning"
      hide-footer
    >
      <template
        #modal-header
      >
        <div class="text-white">
          <strong class="float-left">
            Enlace formulario
          </strong></div>

      </template>
      <!-- BODY -->
      <b-form-group
        label="Nombre del formulario"
        label-for="nuevoNombreFormulario"
      >
        <b-form-textarea
          id="id_enlaceFormulario"
          v-model="enlaceFormulario"
          rows="3"
        />

      </b-form-group>
      <div class="d-flex justify-content-between mt-2 border-top pt-1">
        <b-button
          type="button"
          variant="outline-danger"
          size="sm"
          @click="$bvModal.hide('modal_obtener_enlace')"
        >
          <feather-icon
            icon="XIcon"
            size="14"
          />
          Cancelar
        </b-button>
        <b-button
          :variant="`outline-success`"
          class="mr-2"
          @click="copyEnlace"
        >
          Copiar enlace
          <feather-icon
            icon="CopyIcon"
            size="14"
          />
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BButton, BModal, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormTextarea,
    BButton,
    BModal,
    BFormGroup,
  },

  data() {
    return {
      enlaceFormulario: '',
    }
  },

  created() {
    this.$parent.$on('abrirModalObtenerEnlace', this.abrirModalObtenerEnlace)
  },
  methods: {
    async abrirModalObtenerEnlace(item) {
      if (item.autentificacion === 0) {
        this.enlaceFormulario = `${window.location.origin}/recurso/formulario/anonimo/${item.id}`
      } else {
        this.enlaceFormulario = `${window.location.origin}/recurso/formulario/${item.id}`
      }
      this.$bvModal.show('modal_obtener_enlace')
    },
    copyEnlace() {
      const content = document.getElementById('id_enlaceFormulario')

      content.select()
      document.execCommand('copy')
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: '¡Copiado en el portapapeles!',
          icon: 'CheckCircleIcon',
          variant: 'info',
        },
      })
    },
  },
}
</script>
