<template>
  <div>
    <div v-show="estadoFormulario === true && vistaPrevia === false">
      <div
        class="text-center bg-light-danger rounded py-2"
      >
        <h2 class="text-danger">

          <strong> ¡ FORMULARIO DESHABILITADO !</strong>
          <feather-icon
            icon="FrownIcon"
            class="text-danger ml-2"
            size="24"
          />
          <h4 class="text-center my-2">
            " {{ formulario.nombre }} "
          </h4>
        </h2>
        <small class="text-danger"><strong>(No se aceptan respuestas, comuniquese con el administrador del formulario)</strong></small>
      </div>
    </div>
    <div v-show="estadoFormulario === false || vistaPrevia === true">
      <b-card
        v-show="!formularioRespondido"
        class="mt-0 mb-1"
      >
        <b-row
          class="mb-1 pb-1 "
        >
          <b-col
            v-if="vistaPrevia"
            cols="12"
            class="mb-2"
          >
            <div
              class="text-center bg-light-danger rounded pt-1"
            >
              <h2 class="text-danger">
                <feather-icon
                  icon="EyeIcon"
                  size="20"
                  class="text-danger"
                />
                <strong> FORMULARIO EN VISTA PREVIA </strong>
                <feather-icon
                  icon="EyeIcon"
                  size="20"
                  class="text-danger"
                />
              </h2>
              <small class="text-danger"><strong>(Las respuestas no serán registradas)</strong></small>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-center"
          >

            <div>
              <feather-icon
                icon="ColumnsIcon"
                size="20"
                class="text-primary"
              /> <span
                class="text-primary"
                style="font-size:'18px'"
              ><strong>{{ formulario.nombre }}</strong>

              </span>

            </div>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="12">
            <span>
              <p class="text-justify">{{ formulario.descripcion }}</p>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <secciones
              :vista-previa="vistaPrevia"
              :secciones-formulario="formulario.secciones"
              :id-usuario="idUsuario === 0 ? idUsuarioPredefinido : idUsuario"
              @fomularioCompleto="fomularioCompleto"
            />
          </b-col>

        </b-row>
      </b-card>
      <b-card v-show="formularioRespondido">
        <b-card class="bg-light-primary border-success">
          <div class="text-center">
            <h2 class="text-primary mr-1 pt-1">
              <strong>¡FORMULARIO COMPLETADO!</strong>
            </h2>
            <feather-icon
              icon="ThumbsUpIcon"
              class="text-primary"
              size="25"
            />
            <br>
            <br>

            <b-button
              v-if="autentificacion === 1"
              size="sm"
              variant="outline-primary"
              @click="editarRespuestas"
            >
              <feather-icon
                icon="Edit2Icon"
                size="12"
              />
              Editar respuestas
            </b-button>

            <b-button
              v-else
              size="sm"
              variant="outline-primary"
              @click="recargarPagina"
            >
              Enviar nueva respuesta
            </b-button>

          </div>
        </b-card>
      </b-card>
    </div>

  </div>
</template>

<script>

import {
  BRow, BCard, BCol, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { mapState } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import formularioStoreModule from '@/views/administrador/gestion-formulario/formulario/formularioStoreModule'
import secciones from '@/views/administrador/gestion-formulario/formulario/Secciones.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    secciones,
  },
  props: {
    vistaPrevia: {
      type: Boolean,
      default: false,
    },
    idFormulario: {
      type: Number,
      default: 0,
    },
    idUsuario: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formulario: {},
      formularioRespondido: false,
      idUsuarioPredefinido: 0,
    }
  },
  computed: {
    ...mapState('formularioModule', ['respuestaCondicional', 'estadoFormulario', 'autentificacion']),

  },
  created() {
    if (localStorage.getItem('userData') !== null) {
      this.idUsuarioPredefinido = JSON.parse(localStorage.getItem('userData')).id
    }

    if (this.$router.currentRoute.params.id_formulariox !== undefined) {
      store.state.formularioModule.formularioId = this.$router.currentRoute.params.id_formulariox
    } else {
      store.state.formularioModule.formularioId = this.idFormulario
    }
  },
  mounted() {
    this.cargarFormularioyRespuestas()
  },

  methods: {
    async cargarFormularioyRespuestas() {
      let getDataFormulario = null
      await this.$http.get(`/recursos/formulario/${store.state.formularioModule.formularioId}`).then(res => {
        if (res.data.autentificacion === 1) {
          getDataFormulario = store.dispatch('formularioModule/getFormularioAuth', { usuario: this.idUsuario === 0 ? this.idUsuarioPredefinido : this.idUsuario })
        } else {
          getDataFormulario = store.dispatch('formularioModule/getFormularioAnonimo')
        }
      })

      getDataFormulario.then(res => {
        this.formulario = res.data
        store.state.formularioModule.autentificacion = res.data.autentificacion
        this.formulario.secciones.forEach((value, index) => {
          this.$set(this.respuestaCondicional, index, {})
          this.$set(this.respuestaCondicional[index], 'id', value.id)

          if (value.condicional_respuesta == null) {
            this.$set(this.respuestaCondicional[index], 'value', true)
            // eslint-disable-next-line eqeqeq
          } else if (value.condicional_respuesta.respuesta == '1') {
            this.$set(this.respuestaCondicional[index], 'value', false)
          } else {
            this.$set(this.respuestaCondicional[index], 'value', true)
          }
        })

        if (res.data.estado === 1) {
          store.state.formularioModule.estadoFormulario = false
        } else {
          store.state.formularioModule.estadoFormulario = true
        }
      })
    },
    fomularioCompleto() {
      this.formularioRespondido = true
    },
    editarRespuestas() {
      this.formularioRespondido = false
      this.cargarFormularioyRespuestas()
    },
    recargarPagina() {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    },
  },
  setup() {
    const FORMULARIO_APP_STORE_MODULE_NAME = 'formularioModule'

    // Register module
    if (!store.hasModule(FORMULARIO_APP_STORE_MODULE_NAME)) store.registerModule(FORMULARIO_APP_STORE_MODULE_NAME, formularioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORMULARIO_APP_STORE_MODULE_NAME)) store.unregisterModule(FORMULARIO_APP_STORE_MODULE_NAME)
    })

    return {
      formularioStoreModule,
    }
  },
}
</script>
