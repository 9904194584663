<template>
  <div>
    <b-modal
      id="modal_vista_previa_formulario_satisfaccion"
      ok-only
      size="lg"
      hide-footer
    >
      <template
        #modal-header
      >
        <div class="text-white">
          <strong class="float-left">
            Vista previa
          </strong></div>

      </template>
      <formulario
        :vista-previa="true"
        :id-formulario="idFormulario"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import formulario from '@/views/administrador/gestion-formulario/formulario/Formulario.vue'

export default {
  components: {
    BModal,
    formulario,
  },
  data() {
    return {
      idFormulario: 0,
    }
  },
  created() {
    this.$parent.$on('abrirModalVistaPrevia', this.abrirModalVistaPrevia)
  },
  methods: {
    async abrirModalVistaPrevia(item) {
      this.idFormulario = item.id
      this.$bvModal.show('modal_vista_previa_formulario_satisfaccion')
    },

  },

}
</script>
