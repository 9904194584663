<template>
  <div>
    <ModalDuplicarFormulario @actualizarTablaSatisfaccion="actualizarTablaSatisfaccion" />
    <ObtenerEnlace />
    <ModalVistaPrevia />

    <b-card class="mt-0 px-2">

      <b-table
        id="my-table"
        ref="refTablaFormulariosSatisfaccionList"
        :no-provider-filtering="true"
        class="position-relative"
        show-empty
        head-variant="dark"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        caption-top
      >
        <template #table-caption>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
            >
              <span class="text-dark"><strong>GESTIÓN DE FORMULARIO</strong> </span>
            </b-col>
            <b-col
              xl="8"
              lg="8"
              md="8"
              sm="12"
              class="d-flex justify-content-end"
            >
              <div class="mr-1">
                <label>Mostrar</label>
                <b-form-select
                  v-model="perPage"
                  :options="pageOptions"
                  :clearable="false"
                  size="md"
                  style="width:auto"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </div>
              <div class="mr-1">
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Buscar..."
                  size="md"
                />
              </div>
              <!-- <div>
                <b-button
                  variant="primary"
                  @click="agregarNuevoProfesor"
                >
                  Nuevo profesor DIBU
                </b-button>
              </div> -->
            </b-col>

          </b-row>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

        <template #cell(acciones)="row">
          <div class="d-flex justify-content-center">
            <span>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                title="Duplicar formulario"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="duplicarFormulario(row.item)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </span>
            <span>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                title="Obtener enlace"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="obtenerEnlace(row.item)"
              >
                <feather-icon icon="LinkIcon" />
              </b-button>
            </span>

            <span>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                title="Vista previa"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="vistaPreviaFormulario(row.item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </span>
            <span>
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="align-middle text-primary"
                  />
                </template>

                <b-dropdown-item
                  @click="editarFormulario(row.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="verRespuestas(row.item)"
                >
                  <feather-icon icon="ListIcon" />
                  <span class="align-middle ml-50">Respuestas</span>
                </b-dropdown-item>

              </b-dropdown>
            </span>
          </div>
        </template>

        <template #cell(estado)="row">
          <div class="text-center">
            <feather-icon
              size="24"
              :class="row.item.estado ? 'text-success' : 'text-danger'"
              :icon="row.item.estado ? 'ToggleRightIcon' : 'ToggleLeftIcon'"
              @click="actualizarEstado(row.item)"
            />
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BButton, BFormSelect, BPagination, BFormInput,
  BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import satisfaccionestudianteStoreModule from './satisfaccionEstudianteStoreModule'
import ModalDuplicarFormulario from './ModalDuplicarFormulario.vue'
import ObtenerEnlace from './ObtenerEnlace.vue'
import ModalVistaPrevia from './ModalVistaPreviaForm.vue'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BPagination,
    BFormInput,
    BDropdown,
    BDropdownItem,

    ModalDuplicarFormulario,
    ObtenerEnlace,
    ModalVistaPrevia,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    codigoFormulario: { // el codigo er texto
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 30, 50, 200, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

      items: [],
      fields: [
        { key: 'index', label: 'N°', sortable: true },
        { key: 'nombre', label: 'NOMBRE', sortable: true },
        { key: 'estado', label: 'ESTADO', sortable: true },
        { key: 'acciones' },
      ],
    }
  },

  methods: {
    actualizarTablaSatisfaccion() {
      this.filter = null
      this.$refs.refTablaFormulariosSatisfaccionList.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('satisfaccion-estudiante-ocade/getFormularios', { codigoFormulario: this.codigoFormulario, queryParams: ctx })
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        // const items = response.data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    actualizarEstado(item) {
      this.$http.get(`/recursos/formulario/${item.id}/actualizar-estado`).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Estado actualizado!',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.actualizarTablaSatisfaccion()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Error en actualizar estado!',
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
      })
    },
    verFormulario(item) {
      if (item.autentificacion === 0) {
        this.$router.push({ name: 'vista-anonima-x', params: { id_formulariox: item.id } })
        return
      }
      this.$router.push({ name: 'formulario-x', params: { id_formulariox: item.id } })
    },
    editarFormulario(item) {
      this.$router.push({ name: 'formulario-satisfaccion-estudiante', params: { idFormulario: item.id } })
    },

    verRespuestas(item) {
      this.$router.push({ name: 'respuestas-formulario-satisfaccion-estudiante', params: { idFormulario: item.id } })
    },
    vistaPreviaFormulario(item) {
      this.$emit('abrirModalVistaPrevia', item)
    },
    nuevoFormulario() {
      const DATA = [
        {
          nombre: 'FORMULARIO SIN TÍTULO',
          version: 'v1',
          descripcion: 'Sin descripción',
          secciones: [
            {
              titulo: 'Sección sin titulo',
              subtitulo: 'Sin subtítulo ',
              preguntas: [],
            },
          ],
        },
      ]
      this.$http.post('/recursos/formulario', DATA).then(res => {
        this.$router.push({ name: 'nuevo-formulario-index', params: { idFormulario: res.data.id } })
      })
    },

    duplicarFormulario(item) {
      this.$emit('abrirModalDuplicarFormulario', item)
    },
    obtenerEnlace(item) {
      this.$emit('abrirModalObtenerEnlace', item)
    },

  },
  setup() {
    const SATISFACCION_ESTUDIANTE_OCADE_APP_STORE_MODULE_NAME = 'satisfaccion-estudiante-ocade'

    // Register module
    if (!store.hasModule(SATISFACCION_ESTUDIANTE_OCADE_APP_STORE_MODULE_NAME)) store.registerModule(SATISFACCION_ESTUDIANTE_OCADE_APP_STORE_MODULE_NAME, satisfaccionestudianteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SATISFACCION_ESTUDIANTE_OCADE_APP_STORE_MODULE_NAME)) store.unregisterModule(SATISFACCION_ESTUDIANTE_OCADE_APP_STORE_MODULE_NAME)
    })

    return {
      satisfaccionestudianteStoreModule,
    }
  },
}
</script>
<style lang="scss">

</style>
